<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <div class="p-20px">
    <el-card class="m-auto max-width-700px">
      <template #header>
        <h2 class="text-align-center">
          {{ $t('debug.information') }}
        </h2>
      </template>
      <pre id="debugDetails"
        class="overflow-hidden ff-monospace">
        <strong>Username:</strong> {{ account.username }}
        <strong>Company:</strong> {{ account.companyName }} - [{{ account.companyUid }}]
        <strong>Company Type:</strong> {{ companyType }}<span v-if="showManaged">
        <strong>Managed Company:</strong> {{ managedAccountName }} - [{{ companyUid }}]</span>
        <strong>Portal Version:</strong> {{ gitVersion }}
        <strong>API Environment:</strong> {{ apiName }}
        <strong>Build Environment:</strong> {{ environmentName }}
        <strong>Whitelabel:</strong> {{ whitelabelName }}
        <strong>URL Location:</strong> {{ urlLocation }}
        <strong>Session:</strong> {{ account.token }}
        <strong>Browser:</strong> {{ browser }}
        <strong>Operating System:</strong> {{ operatingSystem }}
        <strong>Scripts:</strong>
        <span v-for="(script, index) in scripts"
        :key="index">    - {{ script }}<br></span>
      </pre>
      <div class="text-align-center">
        <p>{{ $t('debug.include-info') }}</p>
        <el-button type="primary"
          @click="copyDebugInfo('#debugDetails')">
          {{ $t('general.copy-to-clipboard') }}
          <i class="fas fa-clipboard"
            aria-hidden="true" />
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import gitVersion from '@/gitVersion.js'
import uaParser from 'ua-parser-js'
import { copyToClipboard } from '@/helpers.js'
import { mapGetters } from 'vuex'

export default {
  name: 'DebugData',

  inject: ['whitelabelName'],

  computed: {
    ...mapGetters('Auth', ['isPartnerAccount', 'isManagedAccount']),
    ...mapGetters('ApplicationContext', ['companyUid', 'isManagedContext']),
    ...mapGetters('ManagedCompanies', ['getManagedAccountFromId']),
    urlLocation() {
      return window.location.origin
    },
    scripts() {
      return Array.from(document.scripts)
        .map(script => {
          return script.src
        })
        .filter(src => {
          return src.indexOf(window.location.origin) === 0
        })
    },
    gitVersion() {
      return gitVersion
    },
    account() {
      return this.$store.state.Auth.data
    },
    company() {
      return this.$store.state.Company.data
    },
    browser() {
      const uaInfo = uaParser(navigator.userAgent)
      return `${uaInfo.browser.name} v${uaInfo.browser.major} (${uaInfo.browser.version})`
    },
    operatingSystem() {
      const uaInfo = uaParser(navigator.userAgent)
      return `${uaInfo.os.name} v${uaInfo.os.version}`
    },
    version() {
      return window.gitVersion
    },
    environmentName() {
      return this.$appConfiguration.environmentName
    },
    apiName() {
      return this.$appConfiguration.apiName
    },
    companyType() {
      return this.isPartnerAccount
        ? 'Partner'
        : this.isManagedAccount
          ? 'Managed' : 'Direct'
    },
    showManaged() {
      return this.isManagedContext && this.isPartnerAccount
    },
    managedAccountName() {
      if (!this.showManaged) return null
      return this.getManagedAccountFromId(this.companyUid).accountName
    },
  },

  methods: {
    copyDebugInfo(target) {
      const contents = document.querySelector(target)
      const range = document.createRange()
      range.selectNode(contents)

      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')

      copyToClipboard(range.toString())
      window.getSelection().removeAllRanges()
    },
  },
}
</script>

<style lang="scss" scoped>
.max-width-700px {
  max-width: 700px;
}

.ff-monospace {
  font-family: monospace;
}
</style>
